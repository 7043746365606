var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-crew-rate-sidebar","visible":_vm.isActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.crewRateData.id ? 'Update' : 'Add New')+" Crew Rate ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){_vm.resetForm();
          hide();}}})],1),_c('validation-observer',{ref:"newCrewRateForm"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.validateForm()}}},[_c('b-form-group',{attrs:{"label":"Number of Crew","label-for":"number-crew"}},[_c('validation-provider',{attrs:{"name":"Number of Crew","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"number-crew","state":errors.length > 0 ? false : null,"name":"number-crew","type":"number","step":".01"},model:{value:(_vm.crewRateData.numOfCrew),callback:function ($$v) {_vm.$set(_vm.crewRateData, "numOfCrew", $$v)},expression:"crewRateData.numOfCrew"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Crew Rate","label-for":"crew-rate"}},[_c('validation-provider',{attrs:{"name":"Rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"crew-rate","state":errors.length > 0 ? false : null,"name":"crew-rate","type":"number","step":".01"},model:{value:(_vm.crewRateData.rate),callback:function ($$v) {_vm.$set(_vm.crewRateData, "rate", $$v)},expression:"crewRateData.rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Office Rate","label-for":"gta-office-rate"}},[_c('validation-provider',{attrs:{"name":"Office Rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"gta-office-rate","state":errors.length > 0 ? false : null,"name":"gta-office-rate","type":"number","step":".01"},model:{value:(_vm.crewRateData.officeFee),callback:function ($$v) {_vm.$set(_vm.crewRateData, "officeFee", $$v)},expression:"crewRateData.officeFee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Driver Rate","label-for":"driver-rate"}},[_c('validation-provider',{attrs:{"name":"Driver Rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"driver-rate","state":errors.length > 0 ? false : null,"name":"driver-rate","disabled":""},model:{value:(_vm.driverRateComputed),callback:function ($$v) {_vm.driverRateComputed=$$v},expression:"driverRateComputed"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":"","variant":"light"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.crewRateData.id ? 'Update' : 'Add')+" ")],1),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){_vm.resetForm();
              hide();}}},[_vm._v(" Cancel ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }