import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useCrewRate = () => {
  const toast = useToast();

  const createCrewRate = async (crewRate) => {
    try {
      await store.dispatch('crewRate/create', crewRate);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error creating the crew rate',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const deleteCrewRate = async (crewRate) => {
    try {
      await store.dispatch('crewRate/remove', crewRate);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error deleting the crew rate',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getCrewRates = async () => {
    try {
      return await store.dispatch('crewRate/getList');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the list of crew rate',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const updateCrewRate = async (crewRate) => {
    try {
      await store.dispatch('crewRate/update', crewRate);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error updating the crew rate',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    // Method
    createCrewRate,
    deleteCrewRate,
    getCrewRates,
    updateCrewRate,
  };
};

export default useCrewRate;
