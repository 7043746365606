import { ref } from '@vue/composition-api';
import useCrewRate from './useCrewRate';

const useCrewList = () => {
  const { getCrewRates } = useCrewRate();

  const refCrewRateTable = ref(null);
  const tableColumns = [
    { key: 'numOfCrew' },
    { key: 'rate' },
    { key: 'officeFee' },
    { key: 'driverRate' },
    { key: 'actions' },
  ];

  const getCrewRateList = (_, callback) => {
    getCrewRates().then((rates) => {
      callback(rates);
    });
  };

  return {
    // Reactive
    refCrewRateTable,
    tableColumns,

    // Method
    getCrewRateList,
  };
};

export default useCrewList;
