<template>
  <div>
    <crew-form
      v-if="isCrewRateFormActive"
      :is-active.sync="isCrewRateFormActive"
      :crewRate="crewRate"
      @form-submitted="formSubmitted"
    />

    <b-card no-body>
      <div class="m-2">
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            @click="openForm"
          >
            <span class="text-nowrap">Add Crew Rate</span>
          </b-button>
        </div>
      </div>

      <b-table
        ref="refCrewRateTable"
        class="position-relative"
        :items="getCrewRateList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No records found"
      >
        <template #cell(numOfCrew)="data">
          <p class="font-weight-bold d-block text-nowrap">
            {{ data.item.numOfCrew }}
          </p>
        </template>

        <template #cell(rate)="data">
          <p class=" d-block text-nowrap">
            £{{ data.item.rate }}
          </p>
        </template>

        <template #cell(officeFee)="data">
          <p class=" d-block text-nowrap">
            £{{ data.item.officeFee }}
          </p>
        </template>

        <template #cell(driverRate)="data">
          <p class=" d-block text-nowrap">
            £{{ data.item.driverRate }}
          </p>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editRate(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BButton, BCard, BCol, BDropdown, BDropdownItem, BRow, BTable } from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import CrewForm from '@/modules/rate/components/CrewForm.vue';
import useCrewList from "@/modules/rate/composables/useCrewList";
import useCrewRate from "@/modules/rate/composables/useCrewRate";
import Swal from 'sweetalert2';

export default {
  setup() {
    const { deleteCrewRate } = useCrewRate();
    const { refCrewRateTable, tableColumns, getCrewRateList } = useCrewList();

    const crewRateEmpty = {
      officeFee: null,
      id: null,
      numOfCrew: null,
      rate: null
    };

    const isCrewRateFormActive = ref(false);
    const crewRate = ref({ ...crewRateEmpty });

    const editRate = (rate) => {
      crewRate.value = { ...rate };
      isCrewRateFormActive.value = true;
    };

    const formSubmitted = () => {
      isCrewRateFormActive.value = false;
      crewRate.value = { ...crewRateEmpty };
      refCrewRateTable.value.refresh();
    };

    const openForm = () => {
      crewRate.value = { ...crewRateEmpty };
      isCrewRateFormActive.value = true;
    };

    const removeRate = async (rate) => {
      const { value } = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await deleteCrewRate(rate);

      refCrewRateTable.value.refresh();
    };

    return {
      // Reactive
      crewRate,
      isCrewRateFormActive,
      tableColumns,
      refCrewRateTable,

      // Method
      editRate,
      formSubmitted,
      getCrewRateList,
      openForm,
      removeRate
    };
  },
  components: {
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BRow,
    BTable,
    CrewForm,
  }
};
</script>